<template>
  <div id="contactsView" class="viewComp">
    <div class="main">
      <h1>Наши контакты</h1>
      <div class="texts">
        <p>
          <a href="mailto:s.marakushin@owlmedia.kz">s.marakushin@owlmedia.kz</a>
        </p>
        <p>
          <a href="tel:+77772248012">+7 777 224 80 12</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contacts"
};
</script>
