<template>
  <div id="casesView" class="viewComp">
    <div class="main">
      <h1>Кейсы</h1>
      <div class="texts">
        <p>
          За годы работы на рынке мы собрали множество успешных кейсов в своем портфеле.
        </p>
        <p>
          Наша команда и опыт помогают реализовать качественные проекты в заданные сроки и на высшем уровне.
        </p>
        <p>
          Мы делаем свою работу на СОВесть!
        </p>
        <a href="#down" class="more">
          <span class="visually-hidden">ещё</span>
        </a>
      </div>
    </div>
    <div id="down">
      <div class="clear"></div>
      <div class="main">
        <div>
          <img src="../assets/logos/kt.svg" alt="Казахтелеком">
        </div>
        <div>
          <img src="../assets/logos/kcell.svg" alt="KCell">
        </div>
        <div>
          СОВершенствуем стратегическое и коммуникационное планирование, ATL 360°, лидогенерацию и креативную концепцию.
        </div>
        <div>
          Работаем на СОВесть с диджитал и радио баингом, лидогенерацией.
        </div>
        <div>
          <img src="../assets/logos/qazkom.svg" alt="Qazkom">
        </div>
        <div>
          <img src="../assets/logos/global.svg" alt="Global">
        </div>
        <div>
          СОВершаем коммуникационное планирование ATL 360°, оптимизацию рекламных затрат и лидогенерацию.
        </div>
        <div>
          СОВместно работаем со стратегическим медиапланированием в Digital-каналах, оптимизацией наружной рекламы и
          лидогенерацией.
        </div>
        <div>
          <img src="../assets/logos/alser.svg" alt="Alser">
        </div>
        <div>
          <img src="../assets/logos/bosch.png" alt="Bosch">
        </div>
        <div>
          СОВмещаем планирование и запуск рекламных кампаний в Digital для увеличения реальной посещаемости магазинов.
        </div>
        <div>
          СОВершенно ведем SMM и занимаемся закупом наружной рекламы.
        </div>
        <div>
          <img src="../assets/logos/mareven.svg" alt="Mareven">
        </div>
        <div>
          <img src="../assets/logos/chf.svg" alt="Chocofood">
        </div>
        <div>
          Провели СОВершенный SMM-запуск нового продукта — Actibo.
        </div>
        <div>
          Организовали запуск СОВременной онлайн-платформы в традиционных медиа каналах: наружная реклама и радио.
        </div>
      </div>
      <div class="footer">
        СОВершенствуйте бизнес с нами!
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PR",
  data() {
    return {
      navPoint: window.innerHeight
    };
  },
  mounted() {
    this.getPoint();
    window.addEventListener("resize", this.getPoint);
    window.addEventListener("scroll", this.checkPoint);
    this.checkPoint();
  },
  destroyed() {
    window.removeEventListener("resize", this.getPoint);
    window.removeEventListener("scroll", this.checkPoint);
  },
  methods: {
    getPoint() {
      const vh = window.innerHeight / 100;
      let point =
        document.getElementById("down").getBoundingClientRect().top +
        window.scrollY;
      point /= 3;
      point -= vh * 10;
      this.navPoint = point;
    },
    checkPoint() {
      const nav = document.getElementsByTagName("nav")[0];
      if (window.scrollY < this.navPoint) {
        nav.classList.remove("hided");
      } else {
        nav.classList.add("hided");
      }
    }
  }
};
</script>
