<template>
  <div id="prView" class="viewComp">
    <div class="main">
      <h1>PR</h1>
      <div class="texts">
        <p>
          Рассылка пресс-релизов по новостным порталам — не наш метод, слишком
          мелко! Мы выстраиваем качественные и долгосрочные взаимоотношения со
          СМИ, интернет-площадками и авторитетными лидерами мнений.
        </p>
        <p>
          Мы используем комплексный подход в развитии PR-кампании вашего бренда:
        </p>
        <p>вовремя тушим «пожары» негодования публики;</p>
        <p>быстро реагируем на плохие новости;</p>
        <p>выстраиваем долгосрочный позитивный имидж.</p>
        <p>
          Грамотные коммуникации, антикризисные программы и даже разработка
          правильного общения с правительственными ведомствами — с нами любые
          виды PR-коммуникаций будут вам доступны!
        </p>
        <a href="#down" class="more" @click.prevent="smoothDown">
          <span class="visually-hidden">ещё</span>
        </a>
      </div>
    </div>
    <div id="down">
      <div class="clear"></div>
      <div class="main">
        <h1>SMM</h1>
        <p>
          <strong>
            Правильный SMM — это малая часть большого PR. То как мы выглядим в
            социальных сетях прямо влияет на имидж.<br />
            Обратившись к нам, вы получите СОВершенный SMM: позитивный имидж в
            социальных сетях обеспечен!
          </strong>
        </p>
        <p>Мы предлагаем:</p>
        <p>
          Оригинальность<br />
          Гайды — это хорошо, жаль, что они не всегда подходят для SMM. Мы
          стремимся отличаться от других, предлагая свое виденье. Найдем вашу
          изюминку и бренд заиграет новыми красками, чем и запомниться
          подписчикам.
        </p>
        <p>
          Нативность<br />
          «Купи-купи-купи», — насколько же избита эта фраза! Старыми методами
          уже не пробить баннерную слепоту. Мы делаем контент для людей:
          простой, понятный и интересный вашей ЦА.
        </p>
        <p>
          Экспертность<br />
          Но наши идеи настолько креативны и свежи, что заказчики проникаются
          ими. Мы компилируем нашу креативность и задумки клиента в общую идею.
        </p>
        <p>
          Жить новизной<br />
          Наш отдел следит за трендами: последние тенденции и самые модные
          ноу-хау будут на ваших страницах.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PR",
  data() {
    return {
      navPoint: window.innerHeight,
    };
  },
  mounted() {
    this.getPoint();
    window.addEventListener("resize", this.getPoint);
    window.addEventListener("scroll", this.checkPoint);
    this.checkPoint();
  },
  destroyed() {
    window.removeEventListener("resize", this.getPoint);
    window.removeEventListener("scroll", this.checkPoint);
  },
  methods: {
    smoothDown() {
      const offsetTop = document.querySelector('#down').offsetTop;

      scroll({
        top: offsetTop,
        behavior: "smooth"
      });
    },
    getPoint() {
      const vh = window.innerHeight / 100;
      let point =
        document.getElementById("down").getBoundingClientRect().top +
        window.scrollY;
      point /= 3;
      point -= vh * 10;
      this.navPoint = point;
    },
    checkPoint() {
      const nav = document.getElementsByTagName("nav")[0];
      if (window.scrollY < this.navPoint) {
        nav.classList.remove("dark");
      } else {
        nav.classList.add("dark");
      }
    },
  },
};
</script>
